import { ChainId, Currency, ETHER, JSBI, TokenAmount, WETH } from "@heshiswap/sdk";
import { useMemo } from "react";
import { USDT } from "../constants";
// import { USDC, USDT } from "../constants";
import { usePairs } from "../data/Reserves";
import { useTotalSupply } from "../data/TotalSupply";
import { useActiveWeb3React } from "../hooks";
import { wrappedCurrency } from "./wrappedCurrency";

export default function usePairHOO(currencyA?: Currency, currencyB?: Currency, amount?: TokenAmount) {
  const { chainId } = useActiveWeb3React()

  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [tokenA, tokenB],
      [tokenA, chainId ? WETH[chainId] : undefined],
      [tokenB, chainId ? WETH[chainId] : undefined],
      [tokenA, chainId === ChainId.MAINNET ? USDT : undefined],
      [tokenB, chainId === ChainId.MAINNET ? USDT : undefined],
      [chainId ? WETH[chainId] : undefined, chainId === ChainId.MAINNET ? USDT : undefined]
    ],
    [chainId, tokenA, tokenB]
  )
  const [[, pair], [, tokenAHOOPair], [, tokenBHOOPair], [, tokenAUSDTPair], [, tokenBUSDTPair], [, usdtHOOPair]] = usePairs(tokenPairs, true)

  const totalSupply = useTotalSupply(pair?.liquidityToken)

  return useMemo(() => {
    if (!chainId || !tokenA || !tokenB || !amount) return undefined

    const HOO = currencyA === ETHER ? tokenA : tokenB

    if (currencyA !== ETHER && currencyB !== ETHER) {
      if (!pair || !totalSupply) return undefined

      if (tokenAHOOPair && tokenBHOOPair) {
        const tokenAHOO = tokenAHOOPair.priceOf(tokenA).quote(new TokenAmount(tokenA, JSBI.divide(JSBI.multiply(amount.raw, pair.reserveOf(tokenA).raw), totalSupply.raw)))
        const tokenBHOO = tokenBHOOPair.priceOf(tokenB).quote(new TokenAmount(tokenB, JSBI.divide(JSBI.multiply(amount.raw, pair.reserveOf(tokenB).raw), totalSupply.raw)))

        return tokenAHOO.add(tokenBHOO)
      }

      const usdtPair = tokenAUSDTPair ?? tokenBUSDTPair
      if (usdtPair && usdtHOOPair) {
        return usdtHOOPair.priceOf(USDT).quote(new TokenAmount(USDT, JSBI.divide(JSBI.multiply(JSBI.multiply(amount.raw, usdtPair.reserveOf(USDT).raw), JSBI.BigInt(2)), totalSupply.raw)))
      }

      return undefined
    }

    if (!pair || !amount || amount.equalTo('0')) return undefined

    const hooValue = new TokenAmount(
      HOO,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(amount.raw, pair.reserveOf(HOO).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        amount.raw
      )
    )

    return hooValue
  }, [chainId, currencyA, currencyB, totalSupply, pair, amount, tokenA, tokenB, tokenAHOOPair, tokenBHOOPair, tokenAUSDTPair, tokenBUSDTPair, usdtHOOPair])
}
