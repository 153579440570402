import React, { useMemo } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { ETHER, JSBI, TokenAmount } from '@heshiswap/sdk'
import { ButtonPrimary } from '../Button'
import { StakingMultipleRewardInfo } from '../../state/stake/hooks'
import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { CardNoise, CardBGImage, Break } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import usePairHOO from '../../utils/usePairHOO'
import { usePair } from '../../data/Reserves'
// import usePairHOO from '../../utils/usePairHOO'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${showBackground ? theme.black : theme.bg5} 100%) `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function MultipleRewardsPoolCard({ stakingInfo }: { stakingInfo: StakingMultipleRewardInfo }) {
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]

  const earnedToken = stakingInfo.earnedToken.map(token => unwrappedToken(token))

  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  const token = currency0 === ETHER ? token1 : token0
  const backgroundColor = useColor(token)

  const lpValue = usePairHOO(currency0, currency1, stakingInfo.totalStakedAmount)
  const [, pair] = usePair(currency0, currency1, true)

  const rewardHOOValue = new TokenAmount(stakingInfo.earnedToken[0],
    JSBI.multiply(stakingInfo.totalRewardRate[0].raw, JSBI.BigInt(`${60 * 60 * 24}`)))

  const rewardLDTValue = useMemo(() => {
    if (!pair) return new TokenAmount(stakingInfo.earnedToken[1], '0')

    return pair.priceOf(stakingInfo.earnedToken[1]).quote(new TokenAmount(stakingInfo.earnedToken[1],
      JSBI.multiply(stakingInfo.totalRewardRate[1].raw, JSBI.BigInt(`${60 * 60 * 24}`))))
  }, [pair, stakingInfo])

  const finished = stakingInfo.periodFinish && Date.now() > +stakingInfo.periodFinish

  let apy
  if (lpValue && lpValue.greaterThan('0') && rewardHOOValue && !finished) {
    apy = rewardHOOValue.multiply('36500').divide(lpValue).toSignificant(4)
  }
  let apy2
  if (lpValue && lpValue.greaterThan('0') && rewardLDTValue && !finished) {
    apy2 = rewardLDTValue.multiply('36500').divide(lpValue).toSignificant(4)
  }

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '8px' }}>
          {currency0.symbol}-{currency1.symbol} {finished && '(Finished)'}
        </TYPE.white>

        <StyledInternalLink to={`/multifarm/${currencyId(currency0)}/${currencyId(currency1)}`} style={{ width: '100%' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <StatContainer>
        <RowBetween>
          <TYPE.white> Total deposited</TYPE.white>
          <TYPE.white>
              {`${stakingInfo?.totalStakedAmount?.toSignificant(4)} ${currency0?.symbol}/${currency1?.symbol}`}
          </TYPE.white>
        </RowBetween>
        {!finished && (
        <>
          {stakingInfo.totalRewardRate.map((rate, index) =>
            <RowBetween>
              <TYPE.white>{index === 0 && ' Pool rate'}</TYPE.white>
              <TYPE.white>{`${rate
                ?.multiply(`${60 * 60 * 24 * 7}`)
                ?.toFixed(0, { groupSeparator: ',' })} ${earnedToken[index].symbol} / week`}</TYPE.white>
            </RowBetween>
          )}
          <RowBetween>
            <TYPE.white> APY </TYPE.white>
            <TYPE.white>{apy?.toString()} % + {apy2?.toString()} %</TYPE.white>
          </RowBetween>
        </>)}
      </StatContainer>

      {isStaking && !finished && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            {earnedToken.map((token, index) =>
              <RowBetween>
                <TYPE.black color={'white'} fontWeight={500}>
                  {index === 0 && <span>Your rate</span>}
                </TYPE.black>

                <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                    ⚡
                  </span>
                  {`${stakingInfo.rewardRate[index]
                    ?.multiply(`${60 * 60 * 24 * 7}`)
                    ?.toSignificant(4, { groupSeparator: ',' })} ${token.symbol} / week`}
                </TYPE.black>
              </RowBetween>
            )}
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
