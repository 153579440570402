import { ChainId, Currency, JSBI, TokenAmount, WETH } from "@heshiswap/sdk";
import { useMemo } from "react";
import { USDT } from "../constants";
// import { USDC, USDT } from "../constants";
import { usePairs } from "../data/Reserves";
import { useTotalSupply } from "../data/TotalSupply";
import { useActiveWeb3React } from "../hooks";
import { wrappedCurrency } from "./wrappedCurrency";

export default function useTokenHOO(currency?: Currency, amount?: TokenAmount) {
  const { chainId } = useActiveWeb3React()

  const token = wrappedCurrency(currency ?? undefined, chainId)

  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [token, chainId === ChainId.MAINNET ? USDT : undefined],
      [chainId ? WETH[chainId] : undefined, chainId === ChainId.MAINNET ? USDT : undefined]
    ],
    [chainId, token]
  )
  const [[, tokenUSDTPair], [, usdtHOOPair]] = usePairs(tokenPairs, true)

  const totalSupply = useTotalSupply(token)

  return useMemo(() => {
    if (!chainId || !token || !amount || !totalSupply) return undefined

    if (tokenUSDTPair && usdtHOOPair) {
      return usdtHOOPair.priceOf(USDT).quote(new TokenAmount(USDT, JSBI.divide(JSBI.multiply(JSBI.multiply(amount.raw, tokenUSDTPair.reserveOf(USDT).raw), JSBI.BigInt(2)), totalSupply.raw)))
    }

    return undefined
  }, [chainId, totalSupply, amount, token, tokenUSDTPair, usdtHOOPair])
}
