import React from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import CurrencyLogo from '../CurrencyLogo'
import { ButtonPrimary } from '../Button'
import { SingleStakingInfo } from '../../state/stake/hooks'
import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { Break, CardNoise, CardBGImage } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { TokenAmount, JSBI } from '@heshiswap/sdk'
import useTokenHOO from '../../utils/useTokenHOO'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${showBackground ? theme.black : theme.bg5} 100%) `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function SinglePoolCard({ stakingInfo }: { stakingInfo: SingleStakingInfo }) {
  const token = stakingInfo.token
  const currency = unwrappedToken(token)

  const earnedToken = unwrappedToken(stakingInfo.earnedToken)

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  const backgroundColor = useColor(token)

  const tokenValue = useTokenHOO(currency, stakingInfo.totalStakedAmount)
  const rewardValue = useTokenHOO(stakingInfo.earnedToken, new TokenAmount(stakingInfo.earnedToken,
    JSBI.multiply(stakingInfo.totalRewardRate.raw, JSBI.BigInt(`${60 * 60 * 24}`))))

  const finished = stakingInfo.forceFinished || (stakingInfo.periodFinish && Date.now() > +stakingInfo.periodFinish)

  let apy
  if (tokenValue && tokenValue.greaterThan('0') && rewardValue && !finished) {
    apy = rewardValue.multiply('36500').divide(tokenValue).toSignificant(4)
  }

  const showButton = !stakingInfo?.forceFinished || stakingInfo.stakedAmount.greaterThan('0')

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <CurrencyLogo currency={currency} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '8px' }}>
          {currency.symbol} {finished && '(Finished)'}
        </TYPE.white>

        {showButton && <StyledInternalLink to={`/farm/${currencyId(currency)}`} style={{ width: '100%' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Manage' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>}
      </TopSection>

      <StatContainer>
        <RowBetween>
          <TYPE.white> Total deposited</TYPE.white>
          <TYPE.white>
              {`${stakingInfo?.totalStakedAmount?.toSignificant(4)} ${currency?.symbol}`}
          </TYPE.white>
        </RowBetween>
        {!finished && (
        <>
          <RowBetween>
            <TYPE.white> Pool rate </TYPE.white>
            <TYPE.white>{`${stakingInfo.totalRewardRate
              ?.multiply(`${60 * 60 * 24 * 7}`)
              ?.toFixed(0, { groupSeparator: ',' })} ${earnedToken.symbol} / week`}</TYPE.white>
          </RowBetween>
          <RowBetween>
            <TYPE.white> APY </TYPE.white>
            <TYPE.white>{apy?.toString()} %</TYPE.white>
          </RowBetween>
        </>)}
      </StatContainer>

      {isStaking && !finished && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {`${stakingInfo.rewardRate
                ?.multiply(`${60 * 60 * 24 * 7}`)
                ?.toSignificant(4, { groupSeparator: ',' })} ${earnedToken.symbol} / week`}
            </TYPE.black>
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
