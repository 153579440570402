import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, Pair, WETH } from '@heshiswap/sdk'
import { useMemo } from 'react'
import { UNI, USDC, USDT } from '../../constants'
import { STAKE_POOL_INTERFACE } from '../../constants/abis/Stake'
import { STAKING_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1620475500

export const REWARDS_DURATION_DAYS = 7

// TODO add staking rewards addresses here
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
    earnedToken: Token
    forceFinished?: boolean
  }[]
} = {
  [ChainId.MAINNET]: [
    {
      tokens: [WETH[ChainId.MAINNET], USDT],
      stakingRewardAddress: '0x36BD50bA678c74EFb5c73426CdaB106d4132eAf2',
      earnedToken: WETH[ChainId.MAINNET]
    },
    {
      tokens: [WETH[ChainId.MAINNET], USDC],
      stakingRewardAddress: '0x28c4D6fAa371C46de5b6704f591f9A8D73d72CE7',
      earnedToken: WETH[ChainId.MAINNET]
    },
    {
      tokens: [USDT, USDC],
      stakingRewardAddress: '0xE90547570ee95e6929A0DF0434FF9b5fC8050B6E',
      earnedToken: WETH[ChainId.MAINNET]
    },
    {
      tokens: [
        USDT,
        new Token(ChainId.MAINNET, '0x56B0C9B12E8480b4C865322CB3d5CEf1c22c1430', 18, 'SHIB', 'Shiba Inu')
      ],
      stakingRewardAddress: '0xaD0cB404A43254c73E18e0907a2C2FEc77A2B266',
      earnedToken: WETH[ChainId.MAINNET]
    },
    {
      tokens: [
        USDT,
        new Token(ChainId.MAINNET, '0xd101A55BDfb2e767f96ebCEd3458Fdd3D5Db2fD8', 18, 'META', 'Meta Network')
      ],
      stakingRewardAddress: '0x414F028f12d7c4169D519806576706cd4d21019b',
      earnedToken: new Token(ChainId.MAINNET, '0xd9e2c2b61204837c833a9a301c5da7b500cb3e6d', 18, 'HSB', 'Heshi Network')
    },
    {
      tokens: [
        USDT,
        new Token(ChainId.MAINNET, '0x07f823d3d011f7c612084f04d025f4a026f76afd', 18, 'YUNGE', 'YungeToken')
      ],
      stakingRewardAddress: '0x0439C5D44c3CCCB0c37180D18F2b3D635D809c2D',
      earnedToken: new Token(ChainId.MAINNET, '0x07f823d3d011f7c612084f04d025f4a026f76afd', 18, 'YUNGE', 'YungeToken'),
      forceFinished: true
    }
  ],
  [ChainId.TESTNET]: [
  ]
}

export const SINGLE_STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    token: Token
    stakingRewardAddress: string
    earnedToken: Token
    forceFinished?: boolean
  }[]
} = {
  [ChainId.MAINNET]: [
    {
      token: new Token(ChainId.MAINNET, '0x07f823d3d011f7c612084f04d025f4a026f76afd', 18, 'YUNGE', 'YungeToken'),
      stakingRewardAddress: '0x05A4D476571B06B392e1e2CE5D3e950cfc338d54',
      earnedToken: new Token(ChainId.MAINNET, '0x07f823d3d011f7c612084f04d025f4a026f76afd', 18, 'YUNGE', 'YungeToken'),
      forceFinished: true
    }
  ],
  [ChainId.TESTNET]: [
  ]
}

export const STAKING_MULTIPLE_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
    earnedToken: Array<Token>
    periodFinish?: number
  }[]
} = {
  [ChainId.MAINNET]: [
    {
      tokens: [
        WETH[ChainId.MAINNET],
        new Token(ChainId.MAINNET, '0xd63f3cceef518e183e27615a7d6404d0803210af', 18, 'LDT', 'Lendoo')
      ],
      stakingRewardAddress: '0x57d39b32f4A17683eC75144CE906Cc0Cd87755c3',
      earnedToken: [
        WETH[ChainId.MAINNET],
        new Token(ChainId.MAINNET, '0xd63f3cceef518e183e27615a7d6404d0803210af', 18, 'LDT', 'Lendoo'),
      ],
      periodFinish: 1624107600000
    },
  ],
  [ChainId.TESTNET]: [
    {
      tokens: [
        WETH[ChainId.TESTNET],
        new Token(ChainId.TESTNET, '0x11224889F9C8681607FbB1D8431aD8F207Ea40Ea', 18, 'TT', "Test Token"),
      ],
      stakingRewardAddress: '0x36eb1b02cB7Be3ffA1eE7Bd2A3c7D036002730F7',
      earnedToken: [
        new Token(ChainId.TESTNET, '0x11224889F9C8681607FbB1D8431aD8F207Ea40Ea', 18, 'TT', "Test Token"),
        new Token(ChainId.TESTNET, '0x37255455D1895babF6f4A86D0d4f173478F7FF7C', 18, 'TUSD', 'Test USD'),
      ]
    },
  ]
}

export const OLD_STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
    earnedToken: Token
  }[]
} = {
  [ChainId.MAINNET]: [
    {
      tokens: [WETH[ChainId.MAINNET], USDT],
      stakingRewardAddress: '0x5bd8D9D3ED16db68543c2d8EDb388C480Ae086Cc',
      earnedToken: WETH[ChainId.MAINNET]
    },
    {
      tokens: [WETH[ChainId.MAINNET], USDC],
      stakingRewardAddress: '0x11224889F9C8681607FbB1D8431aD8F207Ea40Ea',
      earnedToken: WETH[ChainId.MAINNET]
    },
    {
      tokens: [USDT, USDC],
      stakingRewardAddress: '0x87978fCCBE12F0D16Be4A9AE62DBB7e9d2087d41',
      earnedToken: WETH[ChainId.MAINNET]
    }
  ]
}

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount

  earnedToken: Token
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount

  forceFinished: boolean
}

export interface SingleStakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  token: Token
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount

  earnedToken: Token
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount

  forceFinished: boolean
}

export interface StakingMultipleRewardInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount

  earnedToken: Array<Token>
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: Array<TokenAmount>
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: Array<TokenAmount>
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: Array<TokenAmount>
  // when the period ends
  periodFinish: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: Array<TokenAmount>
  ) => Array<TokenAmount>
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const stakingRewardAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    stakingRewardAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    stakingRewardAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId) return []

    return stakingRewardAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const earnedToken = info[index].earnedToken

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(earnedToken, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            earnedToken,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()

        memo.push({
          stakingRewardAddress: rewardsAddress,
          earnedToken,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(earnedToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate,
          forceFinished: info[index].forceFinished ?? false
        })
      }
      return memo
    }, [])
  }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, stakingRewardAddresses, totalSupplies])
}

// gets the staking info from the network for the active chain id
export function useSingleStakingInfo(tokenToFilterBy?: Token | null): SingleStakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? SINGLE_STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
          tokenToFilterBy === undefined
              ? true
              : tokenToFilterBy === null
              ? false
              : tokenToFilterBy.equals(stakingRewardInfo.token)
          ) ?? []
        : [],
    [chainId, tokenToFilterBy]
  )
  const stakingRewardAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    stakingRewardAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    stakingRewardAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId) return []

    return stakingRewardAddresses.reduce<SingleStakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const token = info[index].token

        // check for account, if no account set to 0

        const earnedToken = info[index].earnedToken

        const stakedAmount = new TokenAmount(token, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(token, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(earnedToken, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            earnedToken,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()

        memo.push({
          stakingRewardAddress: rewardsAddress,
          earnedToken,
          token,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(earnedToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate,
          forceFinished: info[index].forceFinished ?? false
        })
      }
      return memo
    }, [])
  }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, stakingRewardAddresses, totalSupplies])
}

export function useStakingMultipleRewardInfo(pairToFilterBy?: Pair | null): StakingMultipleRewardInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_MULTIPLE_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const stakingRewardAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(stakingRewardAddresses, STAKE_POOL_INTERFACE, 'balanceOf', accountArg)
  const totalSupplies = useMultipleContractSingleData(stakingRewardAddresses, STAKE_POOL_INTERFACE, 'totalSupply')
  const activeRewards = useMultipleContractSingleData(stakingRewardAddresses, STAKE_POOL_INTERFACE, 'activeRewards')

  const flattenedActiveRewards = useMemo(() => {
    if (!chainId) return []

    return stakingRewardAddresses.reduce<Array<string | undefined>>((memo, rewardsAddress, index) => {
      const activeRewardsState = activeRewards[index]

      if (activeRewardsState && !activeRewardsState.loading) {
        if (activeRewardsState.error) {
          console.error('Failed to load active rewards')
          memo.push(undefined)
          return memo
        }

        memo.push(...activeRewardsState.result?.[0])
      } else {
        memo.push(undefined)
      }

      return memo
    }, [])
  }, [chainId, activeRewards, stakingRewardAddresses])

  const activeRewardsSlices = useMemo(() => {
    if (!chainId) return {}

    let offset = 0

    return stakingRewardAddresses.reduce<{ [address: string]: [number, number] }>((memo, rewardsAddress, index) => {
      const activeRewardsState = activeRewards[index]

      if (activeRewardsState && !activeRewardsState.loading) {
        if (activeRewardsState.error) {
          console.error('Failed to load active rewards')
          return memo
        }

        const addresses = activeRewardsState.result?.[0]

        memo[rewardsAddress] = [offset, addresses.length]
        offset += addresses.length
      }

      return memo
    }, {})
  }, [chainId, activeRewards, stakingRewardAddresses]);

  const earnedAmounts = useMultipleContractSingleData(flattenedActiveRewards, STAKING_REWARDS_INTERFACE, 'earned', accountArg)

  const rewardRates = useMultipleContractSingleData(
    flattenedActiveRewards,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId) return []

    return stakingRewardAddresses.reduce<StakingMultipleRewardInfo[]>((memo, rewardsAddress, index) => {
      const balanceState = balances[index]
      const totalSupplyState = totalSupplies[index]

      if (!activeRewardsSlices[rewardsAddress]) return memo

      const earnedAmountStates = earnedAmounts.slice(activeRewardsSlices[rewardsAddress][0], activeRewardsSlices[rewardsAddress][1])
      const rewardRateStates = rewardRates.slice(activeRewardsSlices[rewardsAddress][0], activeRewardsSlices[rewardsAddress][1])

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        earnedAmountStates &&
        earnedAmountStates.every(state => !state.loading) &&
        earnedAmounts.length > 0 &&
        rewardRateStates &&
        rewardRateStates.every(state => !state.loading)
      ) {
        if (
          balanceState?.error ||
          totalSupplyState.error ||
          earnedAmountStates.some(state => state.error) ||
          rewardRateStates.some(state => state.error)
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const earnedToken = info[index].earnedToken

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const earnedAmounts = earnedAmountStates.map(state => state.result?.[0])
        const totalRewardRate = rewardRateStates.map((state, index) => new TokenAmount(earnedToken[index], JSBI.BigInt(state.result?.[0])))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: Array<TokenAmount>
        ): Array<TokenAmount> => {
          return earnedToken.map((token, index) => new TokenAmount(
            token,
              JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
                ? JSBI.divide(JSBI.multiply(totalRewardRate[index].raw, stakedAmount.raw), totalStakedAmount.raw)
                : JSBI.BigInt(0)
          ))
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        // // const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()
console.warn(info[index].periodFinish)
        memo.push({
          stakingRewardAddress: rewardsAddress,
          earnedToken,
          tokens: info[index].tokens,
          periodFinish: info[index].periodFinish ? new Date(info[index].periodFinish as number) : undefined,
          earnedAmount: earnedToken.map((token, index) => new TokenAmount(token, JSBI.BigInt(earnedAmounts[index]))),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate
        })
      }
      return memo
    }, [])
  }, [chainId, stakingRewardAddresses, balances, info, totalSupplies, activeRewardsSlices, rewardRates, earnedAmounts])
}

export function useOldStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? OLD_STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const stakingRewardAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(stakingRewardAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    stakingRewardAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    stakingRewardAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId) return []

    return stakingRewardAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        // check for account, if no account set to 0

        const earnedToken = info[index].earnedToken

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(earnedToken, JSBI.BigInt(rewardRateState.result?.[0]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            earnedToken,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()

        memo.push({
          stakingRewardAddress: rewardsAddress,
          earnedToken,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(earnedToken, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate,
          forceFinished: false
        })
      }
      return memo
    }, [])
  }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, stakingRewardAddresses, totalSupplies])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined
  const stakingInfos = useStakingInfo()

  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
