import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { SINGLE_STAKING_REWARDS_INFO, STAKING_MULTIPLE_REWARDS_INFO, STAKING_REWARDS_INFO, useSingleStakingInfo, useStakingInfo, useStakingMultipleRewardInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import SinglePoolCard from '../../components/earn/SinglePoolCard'
import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
// import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import MultipleRewardsPoolCard from '../../components/earn/MultipleRewardsPoolCard'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

export default function Earn() {
  const { chainId } = useActiveWeb3React()
  const stakingInfos = useStakingInfo()
  const singleStakingInfos = useSingleStakingInfo()
  const stakingMultipleRewardInfos = useStakingMultipleRewardInfo()

  const DataRow = styled(RowBetween)`
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
  `

  const stakingRewardsExist = Boolean(typeof chainId === 'number' &&
    (STAKING_REWARDS_INFO[chainId]?.length ?? 0) +
    (SINGLE_STAKING_REWARDS_INFO[chainId]?.length ?? 0) +
    (STAKING_MULTIPLE_REWARDS_INFO[chainId]?.length ?? 0) > 0)

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>HeshiSwap liquidity mining</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  Deposit your Liquidity Provider tokens to receive tokens.
                </TYPE.white>
              </RowBetween>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </DataCard>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Participating pools</TYPE.mediumHeader>
          {/* <Countdown exactEnd={stakingInfos?.[0]?.periodFinish} /> */}
        </DataRow>

        <PoolSection>
          {stakingRewardsExist && stakingInfos?.length === 0 && singleStakingInfos?.length === 0 && stakingMultipleRewardInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            'No active rewards'
          ) : (
            <>
              {stakingInfos?.filter(stakingInfo => !stakingInfo.periodFinish || Date.now() <= +stakingInfo.periodFinish).map(stakingInfo => {
                // need to sort by added liquidity here
                return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              })}
              {singleStakingInfos?.map(stakingInfo => {
                // need to sort by added liquidity here
                return <SinglePoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              })}
              {stakingMultipleRewardInfos?.map(stakingInfo => {
                // need to sort by added liquidity here
                return <MultipleRewardsPoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              })}
              {stakingInfos?.filter(stakingInfo => !stakingInfo.forceFinished).filter(stakingInfo => stakingInfo.periodFinish && Date.now() > +stakingInfo.periodFinish).map(stakingInfo => {
                // need to sort by added liquidity here
                return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              })}
            </>
          )}
        </PoolSection>
      </AutoColumn>
    </PageWrapper>
  )
}
